<template>
  <div>
    <EITSignIn />
  </div>
</template>

<script>
import EITSignIn from '@/components/eye/EITSignIn.vue'

export default {
  components: {
    EITSignIn
  }
}
</script>
