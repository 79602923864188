<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">Zentech</div>
      <!-- <h2 class="text-h3 text-lg-h2 mb-4">Injection Track</h2> -->
      <img src="/images/logo.png" alt="InjectionTrack" height="48" class="mr-1">
    </v-responsive>

    <v-card class="pa-2 mx-auto" max-width="600" color="transparent" flat>
      <v-card-text>
        <v-text-field v-model="username" label="Email" solo outlined required></v-text-field>
        <v-text-field type="password" v-model="password" label="Password" solo outlined hide-details></v-text-field>
        <!-- <v-checkbox dense label="Remember me"></v-checkbox> -->
        <div class="mb-4"></div>  
        <v-btn block class="primary" x-large @click="login" :disabled="isDisabled">Sign In</v-btn>
        <v-card-actions class="text-center">
          <span class="error" v-if="errorMessage">{{ errorMessage }}</span>
        </v-card-actions>
        <div class="text-center mt-2 text-body-2">
          <router-link to="./signup">Sign up</router-link>&nbsp;&nbsp;|&nbsp;
          <router-link to="/forgetPassword">Forgot password</router-link>&nbsp;&nbsp;|&nbsp;
          <a href="mailto:info@injtrack.com">Contact us</a>
        </div>

        <!-- <div class="d-flex my-3">
          <v-divider class="my-1"></v-divider>
          <div class="text-overline mx-1">Or Sign In With</div>
          <v-divider class="my-1"></v-divider>
        </div> -->

        <!--<div class="d-flex justify-space-between">
          <v-btn outlined large class="flex-grow-1" color="secondary lighten-2">
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn outlined large class="flex-grow-1 mx-2" color="secondary lighten-2">
            <v-icon>mdi-twitter</v-icon>
          </v-btn>
          <v-btn outlined large class="flex-grow-1" color="secondary lighten-2">
            <v-icon>mdi-google</v-icon>
          </v-btn>
        </div>-->
        <!-- <div class="text-overline text-uppercase mt-3 text-center">
          <v-responsive max-width="280" class="mx-auto">
            By signing in, you agree to the<br/>
            <a href="/termofservice">Terms of Service</a> & <a href="#">Privacy Policy</a>
          </v-responsive>
        </div> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      isDisabled: false,

    };
  },
  methods: {
    login() {

      this.isDisabled = true;
      const http = require('https');
      const staticUsername = this.username;
      const data = JSON.stringify({
        email: staticUsername,
        password: this.password,
      });
      console.log(data);
      const options = {
        hostname: this.$serverAddress,
        port: this.$serverPort,
        path: '/api/users/login',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Content-Length': data.length
        }
      };

      let responseBody = '';

      const req = http.request(options, (res) => {
        console.log(`status code: ${res.statusCode}`);
        if (res.statusCode == 401) {
          this.errorMessage = 'Username or password incorrect';
          this.isDisabled = false;
          return;
        }
        res.on('data', (d) => {
          responseBody += d;
        });
        res.on('error', (e) => {
          this.errorMessage = 'Errors occurred, please contact your admin';
        });
        res.on('end', () => {
          try {
            console.log(responseBody);
            const parsedData = JSON.parse(responseBody);
            const token = parsedData["token"];
            this.$setCookie('user', staticUsername);
            this.$setCookie('token', token);
            this.$router.push('/');
          } catch (e) {
            console.error(e.message);
            this.errorMessage = 'Errors occurred, please contact your admin';
          }
        });
      });
      // 写入数据到请求主体
      req.write(data);
      req.end();
    },

  }
};
</script>
<style>
.error {
  color: white;
  margin-top: 10px;
  padding: 5px;
  border-radius:5px;
}
</style>
